<template>
  <div class="block max-w-7xl mx-auto mt-0 lg:mt-24 relative z-100">
    <div data-aos="fade" class="absolute w-[1400px] -bottom-16 -left-20 block">
      <img src="~/assets/images/process_out.svg" alt="Our process"
           class="hidden lg:block w-1/4 pointer-events-none opacity-[0.03]">
      <img src="~/assets/images/process-small_out.svg" alt="Our process"
           class="w-3/4 lg:hidden block ml-4 -mb-8 pointer-events-none opacity-[0.05]">
    </div>
    <div data-aos="fade" class="flex flex-col lg:flex-row gap-4">
      <a href="#step1" class="w-full lg:w-1/5  p-4 step" @click="step = 1" v-bind:class="{'active': step === 1}">
        <div class="flex">
          <h3 class="flex-grow"><span></span>{{$t('pages.home.process.step1')}}</h3>
          <span class="number">01</span>
        </div>
        <div class="gradient-underline"></div>
      </a>
      <a href="#step2" class="w-full lg:w-1/5  p-4 step" @click="step = 2" v-bind:class="{'active': step === 2}">
        <div class="flex">
          <h3 class="flex-grow"><span></span>{{$t('pages.home.process.step2')}}</h3>
          <span class="number">02</span>
        </div>
        <div class="gradient-underline"></div>
      </a>
      <a href="#step3" class="w-full lg:w-1/5  p-4 step" @click="step = 3" v-bind:class="{'active': step === 3}">
        <div class="flex">
          <h3 class="flex-grow"><span></span>{{$t('pages.home.process.step3')}}</h3>
          <span class="number">03</span>
        </div>
        <div class="gradient-underline"></div>
      </a>
      <a href="#step4" class="w-full lg:w-1/5  p-4 step" @click="step = 4" v-bind:class="{'active': step === 4}">
        <div class="flex">
          <h3 class="flex-grow"><span></span>{{$t('pages.home.process.step4')}}</h3>
          <span class="number">04</span>
        </div>
        <div class="gradient-underline"></div>
      </a>
      <a href="#step5" class="w-full lg:w-1/5  p-4 step" @click="step = 5" v-bind:class="{'active': step === 5}">
        <div class="flex">
          <h3 class="flex-grow"><span></span>{{$t('pages.home.process.step5')}}</h3>
          <span class="number">05</span>
        </div>
        <div class="gradient-underline"></div>
      </a>
    </div>

    <div data-aos="fade" data-aos-delay="100" id="step-container">
      <div id="step1" class="tab flex flex-col lg:flex-row lg:h-[250px] lg:mt-12 p-4 lg:p-0" v-if="step === 1">
        <div class="w-full lg:w-3/5">
          <h4 class="text-2xl mb-8">Phase 1: {{$t('pages.home.process.step1')}}</h4>
          <p class="max-w-xl leading-8">
            {{$t('pages.home.process.step1_text')}}
          </p>
        </div>
        <div class="w-full lg:w-2/5 lg:pl-8 my-8 lg:my-0">
          <h4 class="text-2xl mb-4">{{$t('pages.home.process.deliverables')}}</h4>
          <ul>
            <li>
              <UsersIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step1_1')}}</p>
            </li>
            <li>
              <PuzzlePieceIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step1_2')}}</p>
            </li>
            <li>
              <VariableIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step1_3')}}</p>
            </li>
          </ul>
        </div>
      </div>

      <div id="step2" class="tab flex flex-col lg:flex-row lg:h-[250px] lg:mt-12 p-4 lg:p-0" v-if="step === 2">
        <div class="w-full lg:w-3/5">
          <h4 class="text-2xl mb-8">Phase 2: {{$t('pages.home.process.step2')}}</h4>
          <p class="max-w-xl leading-8">
            {{$t('pages.home.process.step2_text')}}
          </p>
        </div>
        <div class="w-full lg:w-2/5 lg:pl-8 my-8 lg:my-0">
          <h4 class="text-2xl mb-4">{{$t('pages.home.process.deliverables')}}</h4>
          <ul>
            <li>
              <Square3Stack3DIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step2_1')}}</p>
            </li>
            <li>
              <ChartBarSquareIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step2_2')}}</p>
            </li>
            <li>
              <DocumentTextIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step2_3')}}</p>
            </li>
          </ul>
        </div>
      </div>

      <div id="step3" class="tab flex flex-col lg:flex-row lg:h-[250px] lg:mt-12 p-4 lg:p-0" v-if="step === 3">
        <div class="w-full lg:w-3/5">
          <h4 class="text-2xl mb-8">Phase 3: {{$t('pages.home.process.step3')}}</h4>
          <p class="max-w-xl leading-8">
            {{$t('pages.home.process.step3_text')}}
          </p>
        </div>
        <div class="w-full lg:w-2/5 lg:pl-8 my-8 lg:my-0">
          <h4 class="text-2xl mb-4">{{$t('pages.home.process.deliverables')}}</h4>
          <ul>
            <li>
              <MapIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step3_1')}}</p>
            </li>
            <li>
              <RectangleGroupIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step3_2')}}</p>
            </li>
            <li>
              <PaintBrushIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step3_3')}}</p>
            </li>
          </ul>
        </div>
      </div>

      <div id="step4" class="tab flex flex-col lg:flex-row lg:h-[250px] lg:mt-12 p-4 lg:p-0" v-if="step === 4">
        <div class="w-full lg:w-3/5">
          <h4 class="text-2xl mb-8">Phase 4: {{$t('pages.home.process.step4')}}</h4>
          <p class="max-w-xl leading-8">
            {{$t('pages.home.process.step4_text')}}
          </p>
        </div>
        <div class="w-full lg:w-2/5 lg:pl-8 my-8 lg:my-0">
          <h4 class="text-2xl mb-4">{{$t('pages.home.process.deliverables')}}</h4>
          <ul>
            <li>
              <WindowIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step4_1')}}</p>
            </li>
            <li>
              <LockClosedIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step4_2')}}</p>
            </li>
            <li>
              <CubeIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step4_3')}}</p>
            </li>
          </ul>
        </div>
      </div>

      <div id="step5" class="tab flex flex-col lg:flex-row lg:h-[250px] lg:mt-12 p-4 lg:p-0" v-if="step === 5">
        <div class="w-full lg:w-3/5">
          <h4 class="text-2xl mb-8">Phase 5: {{$t('pages.home.process.step5')}}</h4>
          <p class="max-w-xl leading-8">
            {{$t('pages.home.process.step5_text')}}
          </p>
        </div>
        <div class="w-full lg:w-2/5 lg:pl-8 my-8 lg:my-0">
          <h4 class="text-2xl mb-4">{{$t('pages.home.process.deliverables')}}</h4>
          <ul>
            <li>
              <BoltIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step5_1')}}</p>
            </li>
            <li>
              <RocketLaunchIcon class="w-4 h-4 mr-2 text-secondary"/>
              {{$t('pages.home.process.step5_2')}}
            </li>
            <li>
              <ShieldCheckIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step5_3')}}</p>
            </li>
            <li>
              <TrophyIcon class="w-4 h-4 mr-2 text-secondary"/>
              <p>{{$t('pages.home.process.step5_4')}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>



  </div>
</template>
<script setup lang="ts">
import {
  UsersIcon,
  PuzzlePieceIcon,
  VariableIcon,
  Square3Stack3DIcon,
  ChartBarSquareIcon,
  DocumentTextIcon,
  PaintBrushIcon,
  MapIcon,
  RectangleGroupIcon,
  RocketLaunchIcon,
  BoltIcon,
  LockClosedIcon,
  CubeIcon,
  WindowIcon,
  ShieldCheckIcon,
  TrophyIcon
} from "@heroicons/vue/24/solid"

const step = ref(1)


onMounted(() => {
  const tabs = document.querySelectorAll('.step')
  tabs.forEach(tab => {
    tab.addEventListener('click', (e) => {
      e.preventDefault()
      if(window.innerWidth < 1024) {
        const element = document.getElementById('step-container')
        if(element){
          element.scrollIntoView({behavior: "smooth"})
        }
      }
    })
  })
});

</script>